import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

@Injectable({
  providedIn: 'root',
})
export class MenuItems {
  private MENUITEMS: Menu[] = [];

  constructor() {
    this.updateMenuItems();
  }

  // Método para actualizar los elementos del menú según el rol
  updateMenuItems(): void {
    const role = localStorage.getItem('roles');

    if (role === 'SuperAdmin') {
      this.MENUITEMS = [
        { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
        { state: 'Alumnos', name: 'Alumnos', type: 'link', icon: 'group' },
        { state: 'AlumnosHistorico', name: 'Alumnos Historico', type: 'link', icon: 'group' },
        { state: 'AlumnoMora', name: 'Mora', type: 'link', icon: 'group' },
        { state: 'PreInscripcionAlumnos', name: 'Pre-Inscripcion', type: 'link', icon: 'folder_shared' },
        { state: 'detallevp', name: 'Ventas y Pagos', type: 'link', icon: 'credit_card' },
        { state: 'productos', name: 'Productos', type: 'link', icon: 'inventory' },
        { state: 'bancos', name: 'Bancos', type: 'link', icon: 'account_balance' },
        { state: 'pagos', name: 'Pagos', type: 'link', icon: 'wallet' },
        { state: 'depositobanco', name: 'Depositos', type: 'link', icon: 'savings' },
        { state: 'descuentos', name: 'Descuentos', type: 'link', icon: 'production_quantity_limits' },
        { state: 'control-pagos', name: 'Reportes y Control', type: 'link', icon: 'credit_score' },
        { state: 'integracion-notas', name: 'Integracion de Notas', type: 'link', icon: 'credit_score' },
        { state: 'reporterA', name: 'Reportes Academicos', type: 'link', icon: 'bar_chart_4_bars' },
        { state: 'recordCrediticio', name: 'Record Crediticio', type: 'link', icon: 'bar_chart_4_bars' },
        { state: 'mantenimiento', name: 'Mantenimiento', type: 'link', icon: 'build' },
        { state: 'ediciones', name: 'Ediciones', type: 'link', icon: 'av_timer' },
        {state: 'configuraciones', name: 'Configuraciones', type:'link', icon: 'settings'}
        // { state: 'button', type: 'link', name: 'Buttons', icon: 'crop_7_5' },
        // { state: 'grid', type: 'link', name: 'Grid List', icon: 'view_comfy' },
        // { state: 'lists', type: 'link', name: 'Lists', icon: 'view_list' },
        // { state: 'menu', type: 'link', name: 'Menu', icon: 'view_headline' },
        // { state: 'tabs', type: 'link', name: 'Tabs', icon: 'tab' },
        // { state: 'stepper', type: 'link', name: 'Stepper', icon: 'web' },
        // { state: 'expansion', type: 'link', name: 'Expansion Panel', icon: 'vertical_align_center' },
        // { state: 'chips', type: 'link', name: 'Chips', icon: 'vignette' },
        // { state: 'toolbar', type: 'link', name: 'Toolbar', icon: 'voicemail' },
        // { state: 'progress-snipper', type: 'link', name: 'Progress snipper', icon: 'border_horizontal' },
        // { state: 'progress', type: 'link', name: 'Progress Bar', icon: 'blur_circular' },
        // { state: 'dialog', type: 'link', name: 'Dialog', icon: 'assignment_turned_in' },
        // { state: 'tooltip', type: 'link', name: 'Tooltip', icon: 'assistant' },
        // { state: 'snackbar', type: 'link', name: 'Snackbar', icon: 'adb' },
        // { state: 'slider', type: 'link', name: 'Slider', icon: 'developer_mode' },
        // { state: 'slide-toggle', type: 'link', name: 'Slide Toggle', icon: 'all_inclusive' },
      ];
    } else if (role === 'Admin') {
      this.MENUITEMS = [
        { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
        { state: 'Alumnos', name: 'Alumnos', type: 'link', icon: 'group' },
        { state: 'AlumnosHistorico', name: 'Alumnos Historico', type: 'link', icon: 'group' },
        { state: 'AlumnoMora', name: 'Mora', type: 'link', icon: 'group' },
        { state: 'PreInscripcionAlumnos', name: 'Pre-Inscripcion', type: 'link', icon: 'folder_shared' },
        { state: 'detallevp', name: 'Ventas y Pagos', type: 'link', icon: 'credit_card' },
        { state: 'productos', name: 'Productos', type: 'link', icon: 'inventory' },
        { state: 'bancos', name: 'Bancos', type: 'link', icon: 'account_balance' },
        { state: 'pagos', name: 'Pagos', type: 'link', icon: 'wallet' },
        { state: 'depositobanco', name: 'Depositos', type: 'link', icon: 'savings' },
        { state: 'descuentos', name: 'Descuentos', type: 'link', icon: 'production_quantity_limits' },
        { state: 'control-pagos', name: 'Reportes y Control', type: 'link', icon: 'credit_score' },
        { state: 'integracion-notas', name: 'Integracion de Notas', type: 'link', icon: 'credit_score' },
        { state: 'reporterA', name: 'Reportes Academicos', type: 'link', icon: 'bar_chart_4_bars' },
        { state: 'mantenimiento', name: 'Mantenimiento', type: 'link', icon: 'build' },
        { state: 'ediciones', name: 'Ediciones', type: 'link', icon: 'av_timer' },
      ];
    } else if (role === 'Secretaria') {
      this.MENUITEMS = [
        { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
        { state: 'Alumnos', name: 'Alumnos', type: 'link', icon: 'group' },
        { state: 'PreInscripcionAlumnos', name: 'Pre-Inscripcion', type: 'link', icon: 'folder_shared' },
        { state: 'detallevp', name: 'Ventas y Pagos', type: 'link', icon: 'credit_card' },
        // { state: 'productos', name: 'Productos', type: 'link', icon: 'inventory' },
        { state: 'bancos', name: 'Bancos', type: 'link', icon: 'account_balance' },
        { state: 'pagos', name: 'Pagos', type: 'link', icon: 'wallet' },
        { state: 'depositobanco', name: 'Depositos', type: 'link', icon: 'savings' },
        { state: 'descuentos', name: 'Descuentos', type: 'link', icon: 'production_quantity_limits' },
        { state: 'control-pagos', name: 'Reportes y Control', type: 'link', icon: 'credit_score' },
        { state: 'integracion-notas', name: 'Integracion de Notas', type: 'link', icon: 'credit_score' },
        { state: 'reporterA', name: 'Reportes Academicos', type: 'link', icon: 'bar_chart_4_bars' },
        { state: 'mantenimiento', name: 'Mantenimiento', type: 'link', icon: 'build' },
      ];
    } else if (role === 'Maestro') {
      this.MENUITEMS = [
        { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
        { state: 'notas', name: 'Notas', type: 'link', icon: 'description' },
      ];
    } else {
      this.MENUITEMS = [
        { state: '', name: '', type: '', icon: '' }
      ];
    }
  }

  // Método para obtener los elementos del menú
  getMenuitem(): Menu[] {
    return this.MENUITEMS;
  }
}
