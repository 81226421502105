import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceGService } from 'src/app/servicios/service-g.service';
import { LoginI } from 'src/app/modelos/login.interface';
import { Router } from '@angular/router';
import { ResponseI } from 'src/app/modelos/response.interface';
import { VigilanteGuard } from 'src/app/vigilante.guard';
import { MenuItems } from 'src/app/shared/menu-items/menu-items';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  loginForm = new FormGroup({
    Username : new FormControl('', Validators.required),
    Password: new FormControl('', Validators.required)
  })

  constructor( private api:ServiceGService , private router : Router, private vigilante: VigilanteGuard, private menuItems: MenuItems) { }

errorStatus: boolean = false;
errorMsg:any ="";


  ngOnInit(): void {
this.checkLocalStorage();

  }


  checkLocalStorage(){
if (localStorage.getItem('token')){

 this.router.navigate(['inicio']);
}

  }


  onLogin(form: LoginI){

this.api.LoginIn(form).subscribe(data =>{

let dataResponse: ResponseI = data ;

if (dataResponse.status == "ok"){

  localStorage.setItem("token", dataResponse.token);
  localStorage.setItem("usuario", dataResponse.usuario);
  localStorage.setItem("roles", dataResponse.roles);

  this.router.navigate(['inicio']);
  this.menuItems.updateMenuItems();


 }else{

this.errorStatus = true;
this.errorMsg = dataResponse.msgRespuesta;
}



})

  }

}
